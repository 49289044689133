import React from 'react';
import * as S from './styles';
import { Link, useLocation } from 'react-router-dom';

function Header_login() {
  const location = useLocation();

  return (
    <S.Container>
      <S.RightSide>
        <Link to="/info_1">Info 1</Link>
        <Link to="/info_2">Info 2</Link>
        <Link to="/info_3">Info 3</Link>
        {location.pathname !== "/" && ( // Exibe o link "Login" apenas se não estiver na rota de login
          <Link to="/">Login</Link>
        )}
      </S.RightSide>
    </S.Container>
  );
}

export default Header_login;
