import api from '../api';
import secureStorage from '../../utils/SecureStorage';


const VisitaServices = {
  

  removeMask: (value, length) => {
    let newValue = value.replace(/\D/g, '');
    return newValue.slice(-length);
  },

  createVisita: async (visita) => {
    visita.id_rede = secureStorage.getItem("id_r");
    visita.data_update = new Date().toISOString();

    if (!visita.id_rede) {
      console.error("id_rede não encontrado");
      alert("Erro ao salvar o cadastro. ID da rede não encontrado.");
      return;
    }



    try {
      const apiUrl = process.env.REACT_APP_API_BASE_22;
      await api.post(apiUrl, visita, {
        headers: {
          'id_rede': visita.id_rede
        }
      });
      alert("Cadastro Salvo Com Sucesso!");
    } catch (error) {
      console.error("Erro ao salvar o cadastro:", error);
      alert("Erro ao salvar o cadastro. Por favor, verifique os logs para mais detalhes.");
    }
  },

  updateVisita: async (visita) => {
    visita.id_rede = secureStorage.getItem("id_r");
    visita.data_update = new Date().toISOString();
    const _id = visita._id;
    const { _id: omit, ...visitaData } = visita;
    

    if (!visita.id_rede) {
      console.error("id_rede não encontrado");
      alert("Erro ao salvar o cadastro. ID da rede não encontrado.");
      return;
    }



    try {
      const apiUrl = process.env.REACT_APP_API_BASE_22;
      await api.put(`${apiUrl}/${_id}?id_rede=${visita.id_rede}`, visitaData, {
        headers: {
          'id_rede': visita.id_rede
        }
      });
      alert("Cadastro Editado Com Sucesso!");
    } catch (error) {
      console.error("Erro ao atualizar o cadastro:", error);
      alert("Erro ao atualizar o cadastro. Por favor, verifique os logs para mais detalhes.");
    }
  },

  getVisitaById: async (id) => {
    const id_rede = secureStorage.getItem("id_r");
    const apiUrl = process.env.REACT_APP_API_BASE_22;

    if (!id_rede) {
      console.error("id_rede não encontrado");
      throw new Error("ID da rede não encontrado");
    }

    try {
      const response = await api.get(`${apiUrl}/${id}?id_rede=${id_rede}`, {
        headers: {
          'id_rede': secureStorage.getItem("id_r")
        }
      });
      return response.data;
    } catch (error) {
      console.error("Erro ao buscar visita por ID:", error);
      throw error;
    }
  },

  deleteVisita: async (id) => {
    const id_rede = secureStorage.getItem("id_r");


    if (!id_rede) {
      console.error("id_rede não encontrado");
      alert("Erro ao deletar o cadastro. ID da rede não encontrado.");
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_BASE_22;
      await api.delete(`${apiUrl}/${id}?id_rede=${id_rede}`, {
        headers: {
          'id_rede': id_rede
        }
      });
      alert("Cadastro Deletado Com Sucesso!");
    } catch (error) {
      console.error("Erro ao deletar o cadastro:", error);
      alert("Erro ao deletar o cadastro. Por favor, verifique os logs para mais detalhes.");
    }
  }
};

export default VisitaServices;
