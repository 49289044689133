import api from '../api';

const ColaboradorServer = {

  removeMask: (value) => {
    let newValue = value.replace(/[\(\)-\s\.\/]/g, '');
    return newValue;
  },

  // Função para converter data do formato brasileiro (DD/MM/AAAA) para o formato ISO (AAAA-MM-DD)
  convertDataBrParaIso: (dataBr) => {
    const partes = dataBr.split('/');
    if (partes.length === 3) {
      const dataIso = `${partes[2]}-${partes[1]}-${partes[0]}`;
      return dataIso;
    }
    return null;
  },

  validateFields: (colaborador) => {
    const { nome, id_rede } = colaborador;
    if (!nome) {
      alert("Você precisa informar o nome");
      return false;
    } else if (!id_rede) {
      alert("O ID da rede não pode ser vazio");
      return false;
    }
    return true;
  },

  saveColaborador: async (colaborador) => {
    colaborador.cpf_cnpj = ColaboradorServer.removeMask(colaborador.cpf_cnpj);
    colaborador.endereco.cep = ColaboradorServer.removeMask(colaborador.endereco.cep);
    //colaborador.contato.telefone_fixo = ColaboradorServer.removeMask(colaborador.contato.telefone_fixo);
    colaborador.data_update = new Date();
    //if (colaborador.contato.telefone_fixo.length !== 10) {
      //console.error('O telefone fixo deve conter 8 dígitos');
     // return;
    //}

    //colaborador.contato.celular = ColaboradorServer.removeMask(colaborador.contato.celular);
    //if (colaborador.contato.celular.length !== 11) {
     // console.error('O celular deve conter 9 dígitos');
     // return;
    //}

    // Converter data_nascimento para o formato ISO
    if (colaborador.data_nascimento) {
      const dataIso = ColaboradorServer.convertDataBrParaIso(colaborador.data_nascimento);
      if (dataIso) {
        colaborador.data_nascimento = new Date(dataIso).toISOString().slice(0, -5);
      }
    }

    // Converter data_admissao para o formato ISO
    if (colaborador.dadosprofissionais && colaborador.dadosprofissionais.data_admissao) {
      const dataIso = ColaboradorServer.convertDataBrParaIso(colaborador.dadosprofissionais.data_admissao);
      if (dataIso) {
        colaborador.dadosprofissionais.data_admissao = new Date(dataIso).toISOString().slice(0, -5);
      }
    }

    if (!ColaboradorServer.validateFields(colaborador)) return;

    const _id = colaborador._id.$oid ? colaborador._id.$oid : null;
    const { ...colaboradorData } = colaborador;
    const apiUrl1 = process.env.REACT_APP_API_BASE_9;
    const apiUrl2 = process.env.REACT_APP_API_BASE_12;

    try {
      let response;
      if (_id) {
        // Atualizar o registro existente do colaborador
        response = await api.put(`${apiUrl1}${_id}?id_rede=${colaboradorData.id_rede}`, colaboradorData);
        //alert("Colaborador Atualizado Com Sucesso!");
      } else {
        // Criar um novo registro para o colaborador
        response = await api.post(apiUrl2, colaboradorData);
        //alert("Colaborador Salvo Com Sucesso!");
      }
      return response.data; // Assumindo que response.data contém o objeto colaborador com _id
    } catch (error) {
      alert("Erro ao salvar o colaborador. Por favor, verifique os logs para mais detalhes.");
    }
    return {
      _id: '',
      nome: '',
      id_rede: null,
      // Definir os outros campos com seus valores padrão
      // ...
    };
  }
};

export default ColaboradorServer;