import styled from "styled-components";

export const Container = styled.div`
  width: 250px;
  height: 200px;
  box-shadow: -3px 1px 13px -2px rgba(0, 0, 0, 0.73);
  border-radius: 10px;
  margin: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  cursor: pointer;
  transition: all 0.3s ease;
  opacity: ${(props) => (props.done ? 0.5 : 1)};

  &:hover {
    opacity: 0.8;
  }

  img {
    width: 80px;
    height: 80px;
  }

  span {
    margin-top: 10px;
    color: #707070;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
    margin: 20px;
    margin-bottom: 40px; /* Espaçamento adicional na parte inferior */

    img {
      width: 50px;
      height: 50px;
    }

    span {
      font-size: 12px;
    }
  }
`;
