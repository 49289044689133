import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  border-bottom: 5px solid #cf0505;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px; /* Ajustar padding para reduzir espaço em branco */
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 10px 0; /* Reduzir padding em dispositivos móveis */
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px; /* Espaço para a logo no lado esquerdo */

  img {
    height: 35px;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    padding-left: 0; /* Remover o padding lateral no modo móvel */
    order: -1; /* Posiciona a logo acima dos itens de menu no modo móvel */

    img {
      height: 30px;
    }
  }
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px; /* Espaço para os itens de menu no lado direito */

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    padding-right: 0; /* Remover o padding lateral no modo móvel */
    order: 1; /* Garante que os itens de menu venham depois da logo no modo móvel */
  }

  a, button {
    color: #cf0505;
    font-weight: bold;
    text-decoration: none;
    margin: 0 10px;

    &:hover {
      opacity: 0.7;
    }
  }

  .dividir {
    margin: 0 10px;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
      height: 20px;
      margin-right: 5px;
    }

    span {
      color: #cf0505;
      font-weight: bold;
    }
  }
`;
