import { useState } from 'react';
import api from '../api';
import secureStorage from '../../utils/SecureStorage';

const useBuscaPerfil = () => {
  const [loading, setLoading] = useState(false);
  const [searchResults3, setSearchResults3] = useState([]);
  const [error, setError] = useState(null);
  const apiUrl1 = process.env.REACT_APP_API_BASE_16;
  

  const loadregistros3 = async (idL) => {
    setLoading(true);
    try {
      const response = await api.get(`${apiUrl1}${idL}`);
      if (response.data) {
        // Atualiza o estado com os novos dados
        secureStorage.setItem("dataUser", JSON.stringify(response.data));
        setSearchResults3([response.data]); // Esta linha está correta
      } else {
        setError("Erro ao buscar cadastros. Veja o console para mais detalhes.");
        setSearchResults3([]); // Limpa os resultados
      }
    } catch (error) {
      setError("Erro ao buscar cadastros. Veja o console para mais detalhes.");
      setSearchResults3([]); // Limpa os resultados anteriores
    } finally {
      setLoading(false);
    }
  };


  return { loading, searchResults3, error, loadregistros3 };
};

export default useBuscaPerfil;
