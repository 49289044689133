import api from '../api';
import secureStorage from '../../utils/SecureStorage';

class Del {
  static async excluirEstabelecimento(estabelecimentoId) {
    const idRede = secureStorage.getItem("id_r");
    const apiUr = process.env.REACT_APP_API_BASE_3;
    const apiUrl = `${apiUr}${estabelecimentoId}?id_rede=${idRede}`;

    try {
      const response = await api.delete(apiUrl);
      if (response.data && response.data.message) {
        alert(`Cliente excluído com sucesso: ${response.data.message}`);
      } else {
        alert('Cliente excluído com sucesso.');
      }
      // Retorne um novo objeto cliente vazio para reutilização na UI
      return {
        id: '',
        cpf_cnpj: '',
        nome_fantasia: '',
        Razao_Social: '',
        blocked: false,
        inactive: false,
        endereco: {
          rua: '',
          numero: '',
          complemento: '',
          bairro: '',
          cep: '',
          cidade: '',
          estado: '',
          latitude: '',
          longetude: ''
        },
        contato: {
          telefone_fixo: '',
          celular: '',
          email: '',
          WhatsApp: '',
          observacoes: ''
        },
      };
    } catch (error) {
      console.error('Erro na comunicação com a API:', error);
      alert(error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Erro ao excluir o cliente.');
      throw error;
    }
  }
}

export default Del;
