import api from '../api'; // Atualize o caminho para sua instância da API

const buscaProdutoPorGTIN = async (gtin) => {
    try {
      const apiUrl = process.env.REACT_APP_API_BASE_24;
      const response =  await api.get(`${apiUrl}/products?query=${gtin}`);
      console.log(response.data)
      console.log(response.data.produto)
      return response.data.produto;
    } catch (error) {
      console.error("Erro ao buscar produto:", error.response ? error.response.data.error : error.message); // Melhoria no log de erro para lidar com erros que não são de resposta HTTP
      throw error;
    }
  };
  
  export default buscaProdutoPorGTIN;