import React from 'react';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';

import Estabelecimento from '../../assets/Estabelecimento.png';
import Cliente from '../../assets/Cliente.png';
import Colaborador from '../../assets/Colaborador.png';
import Produto from '../../assets/Produto.png';

function CabecarioIcon() {
  const navigate = useNavigate();
  const handleClick = () => navigate('/estabelecimento');
  const handleClick2 = () => navigate('/cliente');
  const handleClick3 = () => navigate('/produto');
  const handleClick4 = () => navigate('/colaborador');

  return (
    <S.IconContainer>
      <img src={Estabelecimento} alt="Estabelecimento" onClick={handleClick} />
      <img src={Cliente} alt="Cliente" onClick={handleClick2} />
      <img src={Colaborador} alt="Colaborador" onClick={handleClick4} />
      <img src={Produto} alt="Produto" onClick={handleClick3} />
    </S.IconContainer>
  )
}

export default CabecarioIcon;
