import React from 'react';
import * as XLSX from 'xlsx';

const GeraExcelPonto = ({ colaboradorAtivo }) => {
    if (!colaboradorAtivo || !colaboradorAtivo.registros) return null;

    // Estrutura para os dados de cabeçalho do colaborador, organizados verticalmente
    const resumo = [
        ['Nome:', colaboradorAtivo.colaborador || 'undefined'],
        ['Total de horas:', colaboradorAtivo.total_horas_trabalhadas || '00:00'],
        ['Horas Extras:', colaboradorAtivo.total_horas_extras || '00:00'],
        ['Atraso:', colaboradorAtivo.total_atrasos || '00:00'],
        ['Banco Horas:', colaboradorAtivo.saldo_banco_horas || '00:00'],
        [] // Linha em branco para separação
    ];

    // Calcula o número máximo de registros (batidas) para criar os cabeçalhos corretamente
    const maxBatidas = colaboradorAtivo.registros.reduce(
        (max, reg) => Math.max(max, reg.batidas.length),
        0
    );

    // Cabeçalhos para os registros de ponto, adaptados ao número máximo de batidas
    const pontoHeaders = [
        'Data', 'Dia da Semana',
        ...Array.from({ length: maxBatidas }, (_, i) => `${i + 1}º Registro`),
        'Total de Horas', 'Horas Extras', 'Atraso'
    ];

    // Dados dos registros de ponto
    const data = colaboradorAtivo.registros.map((registro) => {
        const formattedRegistro = [
            formatData(registro.data),
            getDiaDaSemana(registro.data),
            ...registro.batidas.map((batida) =>
                new Date(batida.timestamp).toLocaleTimeString()
            ),
            ...Array(maxBatidas - registro.batidas.length).fill('Sem registro'),
            registro.horas_trabalhadas || '00:00',
            registro.horas_extras || '00:00',
            registro.atraso || '00:00'
        ];
        return formattedRegistro;
    });

    const exportToExcel = () => {
        const wsData = [...resumo, pontoHeaders, ...data];
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Ponto');

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const fileName = `${colaboradorAtivo.colaborador || 'colaborador'}_Ponto_${year}-${month}.xlsx`;

        XLSX.writeFile(wb, fileName);
    };

    return (
        <button onClick={exportToExcel}>Exportar Excel</button>
    );
};

// Função para obter o dia da semana
const getDiaDaSemana = (data) => {
    const diasDaSemana = [
        'Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira',
        'Quinta-feira', 'Sexta-feira', 'Sábado'
    ];
    const date = new Date(data + 'T00:00:00');
    return diasDaSemana[date.getDay()] || '-';
};

// Função para formatar a data como dd-MM-yyyy
const formatData = (data) => {
    const date = new Date(data + 'T00:00:00');
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

export default GeraExcelPonto;
