import api from '../api';


const PontoService = {
    saveponto: async (clienteData) => {
      const apiUrl = process.env.REACT_APP_API_BASE_27 ; // Ajuste a URL conforme necessário
      ///console.log("Dados que estão sendo enviados:", clienteData);
      try {
        await api.put(apiUrl, clienteData);
        alert("Ajuste salvo com sucesso!");
      } catch (error) {
        //console.error("Erro ao salvar o ajuste:", error);
        if (error.response && error.response.data && error.response.data.error) {
          alert(`Erro ao salvar o ajuste: ${error.response.data.error}`);
        } else {
          alert("Erro ao salvar o ajuste.");
        }
      }
    }
  };
  

export default PontoService;
