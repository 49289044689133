import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as S from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import VisitaServices from '../../services/Visita/VisitaServices';
import { format } from 'date-fns';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import iconDefault from "../../assets/Default.png";
import iconIniciado from "../../assets/Iniciado.png";
import iconTerminado from "../../assets/Terminado.png";
import iconAtrasada from "../../assets/Atrasada.png";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const statusEnum = {
  "0": "Nao iniciado",
  "1": "Iniciado",
  "2": "Concluido",
  "3": "Atrasado"
};

const Visualizar = () => {
  const { id } = useParams();
  const [dados, setDados] = useState(null);

  useEffect(() => {
    const fetchVisita = async () => {
      try {
        const response = await VisitaServices.getVisitaById(id);
        setDados(response);
      } catch (error) {
        console.error("Erro ao buscar visita:", error);
      }
    };

    fetchVisita();
  }, [id]);

  const formatDate = (dateObj) => {
    if (dateObj && dateObj.$date) {
      return format(new Date(dateObj.$date), 'yyyy-MM-dd');
    }
    return 'Data inválida';
  };

  const handleDownload = (base64, description) => {
    const link = document.createElement('a');
    link.href = `data:image/png;base64,${base64}`;
    link.download = `${description}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "0":
        return iconDefault;
      case "1":
        return iconIniciado;
      case "2":
        return iconTerminado;
      case "3":
        return iconAtrasada;
      default:
        return iconDefault;
    }
  };

  const generatePDF = (cliente) => {
    const documentDefinition = {
      content: [
        {
          text: 'Detalhes do Atendimento',
          style: 'header',
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          style: 'tableExample',
          table: {
            widths: [100, '*', 100, '*'],
            body: [
              [
                { text: 'ID Visita', style: 'tableHeader' },
                { text: dados.id ? dados.id : 'ID não disponível', colSpan: 3 },
                {},
                {}
              ],
              [
                { text: 'Data', style: 'tableHeader' },
                { text: formatDate(dados.data), style: 'tableData' },
                { text: 'Status', style: 'tableHeader' },
                { text: statusEnum[dados.status], style: 'tableData' }
              ],
              [
                { text: 'Colaborador', style: 'tableHeader' },
                { text: dados.colaborador.nome, style: 'tableData', colSpan: 3 },
                {},
                {}
              ],
              [
                { text: 'Estabelecimento', style: 'tableHeader' },
                { text: dados.estabelecimento.nome_fantasia, style: 'tableData', colSpan: 3 },
                {},
                {}
              ]
            ]
          }
        },
        {
          text: 'Cliente',
          style: 'subheader',
          margin: [0, 20, 0, 10]
        },
        {
          style: 'tableExample',
          table: {
            widths: [100, '*'],
            body: [
              [
                { text: 'Nome', style: 'tableHeader' },
                { text: cliente.nome, style: 'tableData' }
              ],
              [
                { text: 'CPF/CNPJ', style: 'tableHeader' },
                { text: cliente.cpf_cnpj, style: 'tableData' }
              ],
              [
                { text: 'Endereço', style: 'tableHeader' },
                { text: `${cliente.endereco.rua}, ${cliente.endereco.numero}, ${cliente.endereco.bairro}, ${cliente.endereco.cidade}-${cliente.endereco.estado}`, style: 'tableData' }
              ]
            ]
          }
        },
        ...cliente.produtos.map(produto => [
          {
            text: 'Produtos',
            style: 'subheader',
            margin: [0, 20, 0, 10]
          },
          {
            style: 'tableExample',
            table: {
              widths: [100, '*'],
              body: [
                [
                  { text: 'Marca', style: 'tableHeader' },
                  { text: produto.marca, style: 'tableData' }
                ],
                [
                  { text: 'Produto', style: 'tableHeader' },
                  { text: produto.descricao, style: 'tableData' }
                ]
              ]
            }
          },
          {
            text: 'Fotos do Produto',
            style: 'subheader',
            margin: [0, 10, 0, 10]
          },
          {
            columns: produto.fotos.map(foto => ({
              image: `data:image/png;base64,${foto}`,
              width: 150,
              height: 150,
              margin: [5, 0, 5, 0] // Espaçamento entre as fotos
            })),
            columnGap: 10
          }
        ])
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true
        },
        subheader: {
          fontSize: 16,
          bold: true
        },
        tableExample: {
          margin: [0, 5, 0, 15]
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black'
        },
        tableData: {
          fontSize: 12
        }
      }
    };

    const fileName = `Cliente_${cliente.nome}_${format(new Date(), 'yyyy-MM-dd')}.pdf`;

    pdfMake.createPdf(documentDefinition).download(fileName);
  };

  if (!dados) {
    return <div>Loading...</div>;
  }

  return (
    <S.Container>
      <Header />
      <S.MainContent>
        <S.HorizontalContainer>
          <S.InfoBlock>
            <S.Title>ID Visita</S.Title>
            <S.Info>{dados.id ? dados.id : 'ID não disponível'}</S.Info>
          </S.InfoBlock>
          <S.InfoBlock>
            <S.Title>Data</S.Title>
            <S.Info>{formatDate(dados.data)}</S.Info>
          </S.InfoBlock>
          <S.InfoBlock>
            <S.Title>Status</S.Title>
            <S.Info>
              <S.StatusIcon src={getStatusIcon(dados.status)} alt={statusEnum[dados.status]} />
              {statusEnum[dados.status]}
            </S.Info>
          </S.InfoBlock>
          <S.InfoBlock>
            <S.Title>Colaborador</S.Title>
            <S.Info>{dados.colaborador.nome}</S.Info>
          </S.InfoBlock>
          <S.InfoBlock>
            <S.Title>Estabelecimento</S.Title>
            <S.Info>{dados.estabelecimento.nome_fantasia}</S.Info>
          </S.InfoBlock>
        </S.HorizontalContainer>
        <S.HorizontalContainer>
          <S.InfoBlock>
            <S.Title>Descrição</S.Title>
            <S.Info>{dados.descricao_visita}</S.Info>
          </S.InfoBlock>
        </S.HorizontalContainer>
        <S.VerticalContainer>
          {dados.clientes.map((cliente, index) => (
            <S.ClienteBlock key={cliente.id}>
              <S.ClienteTitle>Cliente {index + 1}</S.ClienteTitle>
              <S.InfoBlock>
                <S.Title>Nome</S.Title>
                <S.Info>{cliente.nome}</S.Info>
              </S.InfoBlock>
              <S.InfoBlock>
                <S.Title>CPF/CNPJ</S.Title>
                <S.Info>{cliente.cpf_cnpj}</S.Info>
              </S.InfoBlock>
              <S.InfoBlock>
                <S.Title>Endereço</S.Title>
                <S.Info>{`${cliente.endereco.rua}, ${cliente.endereco.numero}, ${cliente.endereco.bairro}, ${cliente.endereco.cidade}-${cliente.endereco.estado}`}</S.Info>
              </S.InfoBlock>
              <S.Produtos>
                {cliente.produtos.map((produto) => (
                  <S.Produto key={produto.id}>
                    <S.InfoBlock>
                      <S.Title>Marca</S.Title>
                      <S.Info>{produto.marca}</S.Info>
                    </S.InfoBlock>
                    <S.InfoBlock>
                      <S.Title>Produto</S.Title>
                      <S.Info>{produto.descricao}</S.Info>
                    </S.InfoBlock>
                    <S.ProdutoImagemContainer>
                      {produto.fotos && produto.fotos.map((foto, idx) => (
                        <S.ProdutoImagemWrapper key={idx}>
                          <S.ProdutoImagem src={`data:image/png;base64,${foto}`} alt={`Foto do produto ${produto.descricao}`} />
                          <S.DownloadButton onClick={() => handleDownload(foto, produto.descricao)}>Download</S.DownloadButton>
                        </S.ProdutoImagemWrapper>
                      ))}
                    </S.ProdutoImagemContainer>
                    {produto.faltaprodutos && produto.faltaprodutos.map((falta, idx) => (
                      <div key={idx}>
                        <S.InfoBlock>
                          <S.Title>Falta de Produto</S.Title>
                          <S.Info>{falta.observacao}</S.Info>
                        </S.InfoBlock>
                      </div>
                    ))}
                  </S.Produto>
                ))}
              </S.Produtos>
              <S.DownloadButton onClick={() => generatePDF(cliente)}>Gerar PDF</S.DownloadButton>
            </S.ClienteBlock>
          ))}
        </S.VerticalContainer>
      </S.MainContent>
      <Footer />
    </S.Container>
  );
}

export default Visualizar;
