import ColaboradorServer from './SalvarColaborador';
import PerfilServer from './SalvarPerfil';
import UserServer from './SalvarUser';
import secureStorage from '../../utils/SecureStorage';

class CadastroGeral {
  async salvarTudo(dados) {
    try {
      // Salvar colaborador e obter ID
      dados.colaborador.id_rede = secureStorage.getItem("id_r");
      secureStorage.setItem("email", dados.colaborador.contato.email);
      const colaboradorSalvo = await ColaboradorServer.saveColaborador(dados.colaborador);
      if (!colaboradorSalvo.cadastro_id) throw new Error("Erro ao salvar colaborador.");

      let perfilSalvo;
      try {

        dados.perfil.id_rede = secureStorage.getItem("id_r");
        dados.perfil.tipo_emp = secureStorage.getItem("tipo_emp");
        dados.perfil.id_ligacao = colaboradorSalvo.cadastro_id;
        secureStorage.setItem("id_unico", colaboradorSalvo.cadastro_id);
        secureStorage.setItem("usuario_app", dados.perfil.nomeUser);
        dados.perfil.data_update = new Date();
        perfilSalvo = await PerfilServer.savePerfil(dados.perfil);
        if (!perfilSalvo.cadastro_id) throw new Error("Erro ao salvar perfil.");

      } catch (error) {

        throw error;
      }
      
      try {
      // Preparar dados do usuário com o ID do perfil
      dados.user.email = secureStorage.getItem("email");
      dados.user.id_r = secureStorage.getItem("id_r");
      dados.user.usuario_app = secureStorage.getItem("usuario_app");
      dados.user.id_l = perfilSalvo.cadastro_id;
      dados.user.id_unico = secureStorage.getItem("id_unico");
      const userSalvo = await UserServer.saveUser(dados.user);

      if (userSalvo.token){
      secureStorage.removeItem("email");
      secureStorage.removeItem("usuario_app");
      secureStorage.removeItem("id_unico");
      }
      if (!userSalvo.token) throw new Error("Erro ao salvar usuário.");
    } catch (error) {

      throw error;
    }
      alert("Todos os dados foram salvos com sucesso!");
    } catch (error) {
      ///alert("Houve um erro durante o processo de salvamento. Por favor, verifique os logs para mais detalhes.");
    }
  }
}

export default new CadastroGeral();