import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { IntlProvider } from 'react-intl';
import ptMessages from './translations/pt.json';
import enMessages from './translations/en.json';
import esMessages from './translations/es.json';
import Modal from 'react-modal'; // Adicione esta linha

const messages = {
  pt: ptMessages,
  en: enMessages,
  es: esMessages
};

// Escolha o idioma que você deseja usar
const language = navigator.language.split(/[-_]/)[0];  // idioma do navegador

// Definindo o elemento raiz para o React Modal para acessibilidade
Modal.setAppElement('#root'); // Adicione esta linha

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <IntlProvider locale={language} messages={messages[language]}>
      <App />
    </IntlProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
