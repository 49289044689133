import api from '../api';
import secureStorage from '../../utils/SecureStorage';

const ProdutoService = {
  removeMask: (value, length) => {
    let newValue = value.replace(/\D/g, '');
    return newValue.slice(-length);
  },

  validateFields: (produto) => {
    const { id_rede } = produto;
    if (!id_rede || id_rede.length < 10) {
      alert("Ocorreu um problema. Por favor, entre em contato com o suporte.");
      return false;
    }
    return true;
  },

  saveProduto: async (produto) => {
    if (!ProdutoService.validateFields(produto)) return;


    produto.id_rede = secureStorage.getItem("id_r");
    produto.data_update = new Date();
    const _id = produto._id?.$oid; // Usando optional chaining para segurança
    const { ...produtoData } = produto;
    const apiUrl2 = process.env.REACT_APP_API_BASE_20;
    const apiUrl = process.env.REACT_APP_API_BASE_21;

    try {

      if (_id) {
        produtoData.id = _id;
        await api.put(`${apiUrl2}${_id}?id_rede=${produto.id_rede}`, produtoData);
        alert("Cadastro Editado Com Sucesso!");
      } else {
        await api.post(apiUrl, produtoData);
        alert("Cadastro Salvo Com Sucesso!");
      }
    } catch (error) {
      console.error("Erro ao salvar o cadastro:", error);
      alert("Erro ao salvar o cadastro. Por favor, verifique os logs para mais detalhes.");
    }

    // Retorne um novo objeto produto vazio para reutilização na UI, se necessário
    return {
      id_rede: '',
      cliente: {
        id_cliente: '',
        cliente_nome: ''
      },
      description: '',
      gtin: '',
      thumbnail: '',
      price: '',
      avg_price: '',
      max_price: '',
      min_price: '',
      width: '',
      height: '',
      length: '',
      net_weight: '',
      gross_weight: '',
      barcode_image: '',
      brand: {
        name: '',
        picture: ''
      },
      gpc: {
        code: '',
        description: ''
      },
      ncm: {
        code: '',
        description: '',
        full_description: ''
      },
      cest: {
        id: '',
        code: '',
        description: '',
        parent_id: ''
      },
      gtins: [
        {
          gtin: '',
          commercial_unit: {
            type_packaging: '',
            quantity_packaging: '',
            ballast: '',
            layer: ''
          }
        },
        {
          gtin: '',
          commercial_unit: {
            type_packaging: '',
            quantity_packaging: '',
            ballast: '',
            layer: ''
          }
        }
      ]
    };
  }
};

export default ProdutoService;
