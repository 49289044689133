import api from '../api';
import secureStorage from '../../utils/SecureStorage';

const UserServer = {
  
    // Função para validar os campos do usuário
    validateFields: (user) => {
      const { username, password, id_r, id_l, tipo_app } = user;
      if (!username) {
        alert("Você precisa informar o nome de usuário");
        return false;
      } else if (!password) {
        alert("Você precisa informar a senha");
        return false;
      } else if (!id_r) {
        alert("O ID da rede não pode ser vazio");
        return false;
      } else if (!id_l) {
        alert("Você precisa informar o ID de ligação");
        return false;
      } else if (!tipo_app) {
        alert("Você precisa informar o tipo de aplicativo");
        return false;
      }
      return true;
    },
  
    // Função para salvar os dados do usuário
    saveUser: async (user) => {
      if (!UserServer.validateFields(user)) return;
  
      
      const { ...userData } = user;
      const _id = userData._id.$oid  ;
      userData.id_rede = secureStorage.getItem("id_r");
      const apiUrl1 = process.env.REACT_APP_API_BASE_16; 
      const apiUrl2 = process.env.REACT_APP_API_BASE_15;
      
  
      try {
        if (userData._id) {
          if (!userData._id) {
            alert("tivemos um problema ao caregar as informar");
            return false;
          }
          await api.put(`${apiUrl1}${userData._id}?id_rede=${userData.id_rede}`, userData);
          //alert("Usuário Atualizado Com Sucesso!");
        } else {
          const response = await api.post(apiUrl2, userData);
          //alert("Usuário Salvo Com Sucesso!");
          return response.data;
        }
      } catch (error) {
        console.error("Erro ao salvar o usuário:", error);
        alert("Erro ao salvar o usuário. Por favor, verifique os logs para mais detalhes.");
      }
      alert("Todos os dados foram salvos com sucesso!");
      
      return {
        _id_perfil: '',
        id_unico: '',
        username: '',
        password: '',
        id_r: null,
        id_l: '',
        tipo_app: '' 
      };
    }
  };
  
  export default UserServer;