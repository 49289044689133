import { useState } from 'react';
import api from '../api';
import secureStorage from '../../utils/SecureStorage';

const BuscaColaborador = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadRegistros = async (idColaborador, onSuccess) => {
    setLoading(true);
    try {
      const idRede = secureStorage.getItem("id_r") || "";
      const response = await api.get(`${process.env.REACT_APP_API_BASE_9}${idColaborador}?id_rede=${idRede}`);

      setError(null);
      if (onSuccess) {
        onSuccess(response.data || null);
      }
    } catch (error) {
      
      setError("Erro ao buscar cadastros. Veja o console para mais detalhes.");
      if (onSuccess) onSuccess(null);
    } finally {
      setLoading(false);
      
    }
  };

  return { loadRegistros, loading, error };
};

export default BuscaColaborador;
