import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
`;

export const Container2 = styled.div`
  height: 90vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  img {
    margin-bottom: 30px;
    margin-top: 10px;
    cursor: pointer;
  }
  a {
    margin-top: 80px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 14px;
  }
`;

export const Input = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;

  span {
    color: #707070;
    margin: 3px 0;
    align-self: flex-start;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input {
    width: 250px;
    height: 20px;
    font-size: 15px;
    padding: 5px;
    border: none;
    border-bottom: 1px solid #cf0505;
  }
`;

export const Save = styled.div`
  width: 17%;
  margin-top: 40px;
  
  button {
    width: 100%;
    align-items: center;
    background: #03520d;
    border: none;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    padding: 5px;
    border-radius: 30px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
`;

