import { useState } from 'react';
import api from '../api'; // Atualize o caminho para sua instância da API
import secureStorage from '../../utils/SecureStorage';

const useBuscaClientes = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_BASE_8;
  let id = secureStorage.getItem("id_r");

  const loadregistros = async (idrede) => {
    setLoading(true);
    try {
      const response = await api.get(`${apiUrl}${idrede}`);
      setSearchResults(response.data.cadastros);
      setError(null); // Limpa qualquer erro anterior
    } catch (error) {
      console.error("Erro ao buscar cadastros:", error);
      setError("Erro ao buscar cadastros. Veja o console para mais detalhes.");
      setSearchResults([]); // Limpa os resultados anteriores
    } finally {
      setLoading(false);
    }
  };

  return { searchResults, loadregistros, loading, error };
};

export default useBuscaClientes;