import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 40px;
  background: #ffffff;
  border-top: 5px solid #cf0505;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between; // Distribui o conteúdo igualmente
  padding: 0 10px; // Adiciona um espaçamento interno de 10px nas laterais

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    height: auto; // Permite que a altura se ajuste conforme o conteúdo
    padding: 10px 0; // Adiciona padding vertical para espaçamento
  }
`;

export const SpanLeftAlign = styled.span`
  text-align: left;
  flex: 1;
  color: #cf0505;
  padding-left: 0; // Remove padding adicional à esquerda

  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 5px; // Adiciona espaçamento inferior em dispositivos móveis
  }
`;

export const SpanCenterAlign = styled.span`
  text-align: center;
  flex: 1;
  color: #cf0505;
  margin-bottom: 5px; // Adiciona espaçamento inferior em dispositivos móveis

  @media (max-width: 768px) {
    margin-bottom: 5px; // Adiciona espaçamento inferior em dispositivos móveis
  }
`;

export const SpanRightAlign = styled.span`
  text-align: right;
  flex: 1;
  color: #cf0505;
  padding-right: 0; // Remove padding adicional à direita

  @media (max-width: 768px) {
    text-align: center;
  }
`;
