import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center; /* Centraliza o conteúdo horizontalmente */
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f8f8;
`;

export const RightSide = styled.div`
  display: flex;
  gap: 20px; /* Espaço entre os links */
`;
