import React from 'react';
import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
`;

const Td = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
`;

function GtinTable({ gtins, setGtins }) {
  const handleChange = (index, field, value) => {
    const newGtins = [...gtins];
    if (field.startsWith('commercial_unit.')) {
      const subField = field.split('.')[1];
      newGtins[index].commercial_unit[subField] = value;
    } else {
      newGtins[index][field] = value;
    }
    setGtins(newGtins);
  };

  return (
    <Table>
      <thead>
        <tr>
          <Th>GTIN</Th>
          <Th>Tipo de Embalagem</Th>
          <Th>Quantidade por Embalagem</Th>
          <Th>Lastro</Th>
          <Th>Camada</Th>
        </tr>
      </thead>
      <tbody>
        {gtins.map((item, index) => (
          <tr key={index}>
            <Td>
              <Input
                type="text"
                value={item.gtin}
                onChange={(e) => handleChange(index, 'gtin', e.target.value)}
              />
            </Td>
            <Td>
              <Input
                type="text"
                value={item.commercial_unit.type_packaging}
                onChange={(e) => handleChange(index, 'commercial_unit.type_packaging', e.target.value)}
              />
            </Td>
            <Td>
              <Input
                type="number"
                value={item.commercial_unit.quantity_packaging}
                onChange={(e) => handleChange(index, 'commercial_unit.quantity_packaging', e.target.value)}
              />
            </Td>
            <Td>
              <Input
                type="text"
                value={item.commercial_unit.ballast || ''}
                onChange={(e) => handleChange(index, 'commercial_unit.ballast', e.target.value)}
              />
            </Td>
            <Td>
              <Input
                type="text"
                value={item.commercial_unit.layer || ''}
                onChange={(e) => handleChange(index, 'commercial_unit.layer', e.target.value)}
              />
            </Td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default GtinTable;
