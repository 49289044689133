import React from 'react';
import { CSVLink } from 'react-csv';

const GeraCsvPonto = ({ colaboradorAtivo }) => {
    if (!colaboradorAtivo || !colaboradorAtivo.registros) return null;

    // Estrutura para os dados de cabeçalho do colaborador, organizados verticalmente
    const resumo = [
        ['Nome:', colaboradorAtivo.colaborador || 'undefined'],
        ['Total de horas:', colaboradorAtivo.total_horas_trabalhadas || '00:00'],
        ['Horas Extras:', colaboradorAtivo.total_horas_extras || '00:00'],
        ['Atraso:', colaboradorAtivo.total_atrasos || '00:00'],
        ['Banco Horas:', colaboradorAtivo.saldo_banco_horas || '00:00'],
        [] // Linha em branco para separação
    ];

    // Calcula o número máximo de registros (batidas) para criar os cabeçalhos corretamente
    const maxBatidas = colaboradorAtivo.registros.reduce(
        (max, reg) => Math.max(max, reg.batidas.length),
        0
    );

    // Cabeçalhos para os registros de ponto, adaptados ao número máximo de batidas
    const pontoHeaders = [
        'Data', 'Dia da Semana',
        ...Array.from({ length: maxBatidas }, (_, i) => `${i + 1}º Registro`),
        'Total de Horas', 'Horas Extras', 'Atraso'
    ];

    // Dados dos registros de ponto
    const data = colaboradorAtivo.registros.map((registro) => {
        // Formata a data e o dia da semana conforme necessário
        const formattedRegistro = [
            formatData(registro.data),
            getDiaDaSemana(registro.data),
            ...registro.batidas.map((batida) =>
                new Date(batida.timestamp).toLocaleTimeString()
            ),
            ...Array(maxBatidas - registro.batidas.length).fill('Sem registro'), // Preenche "Sem registro" para batidas faltantes
            registro.horas_trabalhadas || '00:00',
            registro.horas_extras || '00:00',
            registro.atraso || '00:00'
        ];
        return formattedRegistro;
    });

    // Nome do arquivo CSV com o nome do colaborador, ano e mês
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const fileName = `${colaboradorAtivo.colaborador || 'colaborador'}_Ponto_${year}-${month}.csv`;

    return (
        <CSVLink
            data={[...resumo, pontoHeaders, ...data]}
            filename={fileName}
        >
            <button>Exportar CSV</button>
        </CSVLink>
    );
};

// Função para obter o dia da semana
const getDiaDaSemana = (data) => {
    const diasDaSemana = [
        'Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira',
        'Quinta-feira', 'Sexta-feira', 'Sábado'
    ];
    const date = new Date(data + 'T00:00:00');
    return diasDaSemana[date.getDay()] || '-';
};

// Função para formatar a data como dd-MM-yyyy
const formatData = (data) => {
    const date = new Date(data + 'T00:00:00');
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

export default GeraCsvPonto;
