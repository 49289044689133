import api from '../api'; 
import secureStorage from '../../utils/SecureStorage';

class ProdutoDel {
  static async excluirProduto(produtoId) {
    const idRede = secureStorage.getItem("id_r");
    const apiUrl2 = process.env.REACT_APP_API_BASE_20;
    const apiUrl = `${apiUrl2}${produtoId}?id_rede=${idRede}`;

    try {
      const response = await api.delete(apiUrl);
      if (response.data && response.data.message) {
        alert(`Produto excluído com sucesso: ${response.data.message}`);
      } else {
        alert('Produto excluído com sucesso.');
      }
      // Retorne um novo objeto produto vazio para reutilização na UI
      return {
        id_rede: '',
        description: '',
        gtin: '',
        thumbnail: '',
        price: '',
        avg_price: '',
        max_price: '',
        min_price: '',
        width: '',
        height: '',
        length: '',
        net_weight: '',
        gross_weight: '',
        brand: {
            name: '',
            picture: ''
        },
        gpc: {
            code: '',
            description: ''
        },
        ncm: {
            code: '',
            description: '',
            full_description: ''
        },
        cest: {
            id: '',
            code: '',
            description: '',
            parent_id: ''
        },
        gtins: [
            {
                gtin: '',
                commercial_unit: {
                    type_packaging: '',
                    quantity_packaging: '',
                    ballast: '',
                    layer: ''
                }
            },
            {
                gtin: '',
                commercial_unit: {
                    type_packaging: '',
                    quantity_packaging: '',
                    ballast: '',
                    layer: ''
                }
            }
        ]
      };
    } catch (error) {
      console.error('Erro na comunicação com a API:', error);
      alert(error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Erro ao excluir o produto.');
      throw error;
    }
  }
}

export default ProdutoDel;
