import React, { useState, useEffect } from 'react';
import * as S from './styles';
import { FaCheckCircle, FaTimesCircle, FaMapMarkerAlt, FaPlus, FaTrash } from 'react-icons/fa';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import BuscaPonto from '../../services/ponto/BuscaPonto';
import PontoService from '../../services/ponto/PontoService';
import RemoverPonto from '../../services/ponto/RemoverPontoService';
import secureStorage from '../../utils/SecureStorage';
import GeraCsvPonto from '../../components/Exportar/GeraCsvPonto';
import GeraExcelPonto from '../../components/Exportar/GeraExcelPonto';
import GeraPdfPonto from '../../components/Exportar/GeraPdfPonto';

import Modal from 'react-modal';
import { format } from 'date-fns';

function ChangeMapView({ coords }) {
    const map = useMap();
    map.setView(coords, map.getZoom());
    return null;
}

Modal.setAppElement('#root');

function Ponto() {
    const [colaboradores, setColaboradores] = useState([]);
    const [colaboradorAtivo, setColaboradorAtivo] = useState(null);
    const [localizacaoAtiva, setLocalizacaoAtiva] = useState(null);
    const [colaboradoresUnicos, setColaboradoresUnicos] = useState([]);
    const { loadregistros } = BuscaPonto([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalAjusteIsOpen, setModalAjusteIsOpen] = useState(false);
    const [registroSelecionado, setRegistroSelecionado] = useState(null);

    const loadregistrosponto = async () => {
        const id_rede = secureStorage.getItem("id_r");
        if (id_rede) {
            const error = await loadregistros(id_rede);
            if (error) {
                console.error("Erro ao carregar registros:", error);
            } else if (secureStorage.getItem("dataponto")) {
                let dataUser = secureStorage.getItem("dataponto");
                if (dataUser) {
                    dataUser = JSON.parse(dataUser);
                    const colaboradores = Object.keys(dataUser).map((colaborador) => ({
                        colaborador,
                        registros: dataUser[colaborador].registros,
                        total_horas_trabalhadas: dataUser[colaborador].total_horas_trabalhadas,
                        total_horas_extras: dataUser[colaborador].total_horas_extras,
                        total_atrasos: dataUser[colaborador].total_atrasos,
                        saldo_banco_horas: dataUser[colaborador].saldo_banco_horas,
                        id_colaborador: dataUser[colaborador].registros[0]?.id_colaborador,
                        empresa: dataUser[colaborador].empresa
                    }));

                    setColaboradores(colaboradores);
                    setColaboradoresUnicos(Object.keys(dataUser));

                    setColaboradorAtivo(prev => {
                        if (!prev || prev.colaborador !== colaboradores[0]?.colaborador) {
                            return colaboradores[0];
                        }
                        return prev;
                    });
                }
            }
        }
    };

    useEffect(() => {
        loadregistrosponto();
    }, []);

    const abrirMapa = (localizacao) => {
        if (localizacao.latitude && localizacao.longitude) {
            setLocalizacaoAtiva(localizacao);
            setModalIsOpen(true);
        } else {
            alert("Localização inválida. Não é possível abrir o mapa.");
        }
    };

    const fecharMapa = () => {
        setLocalizacaoAtiva(null);
        setModalIsOpen(false);
    };

    const abrirAjusteManual = (registro) => {
        setRegistroSelecionado(registro);
        setModalAjusteIsOpen(true);
    };

    const fecharAjusteManual = () => {
        setRegistroSelecionado(null);
        setModalAjusteIsOpen(false);
    };

    const salvarAjuste = async () => {
        const horario = document.getElementById('horarioBatida').value;

        if (!horario || horario === "--:--") {
            alert("Por favor, insira um horário válido antes de salvar.");
            return;
        }

        if (registroSelecionado) {
            const novaBatida = {
                timestamp: new Date(`${registroSelecionado.data}T${horario}:00`).toISOString(),
                localizacao: { latitude: 0, longitude: 0, precisao: 0 },
                Registromanual: new Date().toISOString()
            };

            const batidasAtualizadas = [...registroSelecionado.batidas, novaBatida].sort(
                (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
            );

            const clienteData = {
                id_rede: secureStorage.getItem("id_r"),
                empresa: colaboradorAtivo?.registros?.[0]?.empresa || "empresa_exemplo",
                colaborador: colaboradorAtivo?.colaborador,
                id_colaborador: colaboradorAtivo?.registros?.[0]?.id_colaborador,
                data: registroSelecionado.data,
                batidas: batidasAtualizadas,
            };

            try {
                await PontoService.saveponto(clienteData);
                alert("Ajuste salvo com sucesso!");

                const registrosAtualizados = colaboradores.map((colaborador) => {
                    if (colaborador.colaborador === colaboradorAtivo?.colaborador) {
                        const registros = colaborador.registros.map((reg) => {
                            if (reg.data === registroSelecionado.data) {
                                return { ...reg, batidas: batidasAtualizadas };
                            }
                            return reg;
                        });
                        return { ...colaborador, registros };
                    }
                    return colaborador;
                });

                setColaboradores(registrosAtualizados);
                fecharAjusteManual();
                loadregistrosponto();
            } catch (error) {
                console.error("Erro ao salvar o ajuste:", error);
                alert("Erro ao salvar o ajuste. Tente novamente.");
            }
        }
    };

    useEffect(() => {
        if (colaboradorAtivo) {
            console.log("Colaborador ativo atualizado:", colaboradorAtivo);
        }
    }, [colaboradorAtivo]);
    

    const removerBatida = (registro, batida) => {
        if (!registro || !batida) {
            console.error("Erro: registro ou batida inválidos.", { registro, batida });
            alert("Erro: registro ou batida inválidos.");
            return;
        }

        const confirmarRemocao = window.confirm("Você realmente deseja remover este registro?");

        if (confirmarRemocao) {
            removerBatidaPonto(registro, batida);
        }
    };

    const removerBatidaPonto = async (registro, batida) => {
        if (!registro) {
            console.error("Erro: registro não fornecido.", registro);
            alert("Erro: registro não fornecido.");
            return;
        }

        if (!batida || !batida.timestamp) {
            console.error("Erro: batida inválida ou timestamp não encontrado.", batida);
            alert("Erro: batida inválida ou timestamp não encontrado.");
            return;
        }

        const batidasAtualizadas = registro.batidas.filter(b => b.timestamp !== batida.timestamp);

        const registrosAtualizados = colaboradores.map(colaborador => {
            if (colaborador.colaborador === colaboradorAtivo?.colaborador) {
                return {
                    ...colaborador,
                    registros: colaborador.registros.map(reg => {
                        if (reg.data === registro.data) {
                            return { ...reg, batidas: batidasAtualizadas };
                        }
                        return reg;
                    })
                };
            }
            return colaborador;
        });

        setColaboradores(registrosAtualizados);
        setColaboradorAtivo({
            ...colaboradorAtivo,
            registros: registrosAtualizados.find(c => c.colaborador === colaboradorAtivo?.colaborador)?.registros
        });

        
        
        try {
            const clienteData = {
                id_rede: secureStorage.getItem("id_r"),
                empresa: colaboradorAtivo?.registros?.[0]?.empresa || "empresa_exemplo",
                colaborador: colaboradorAtivo?.colaborador,
                id_colaborador: colaboradorAtivo?.registros?.[0]?.id_colaborador,
                data: registro.data,
                batidas: batidasAtualizadas,
                timestamp_removido: batida.timestamp
            };

            const resposta = await RemoverPonto.Removerponto(clienteData);
            console.log('Batida removida com sucesso!', resposta);
            alert("Batida removida com sucesso!");
        } catch (error) {
            console.error("Erro ao remover a batida:", error);
            alert("Erro ao remover a batida. Tente novamente.");
        }
    };

    const defaultIcon = L.icon({
        iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
        shadowSize: [41, 41],
    });

    const getDiaDaSemana = (data) => {
        const diasDaSemana = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
        const date = new Date(data + 'T00:00:00');
        return isNaN(date.getTime()) ? '-' : diasDaSemana[date.getDay()];
    };
    
    
    return (
        <S.Container>
            <Header />
            <S.Content>
                <S.Tabs>
                    {colaboradoresUnicos.map((colaborador, index) => (
                        <S.Tab
                            key={index}
                            $isActive={colaborador === colaboradorAtivo?.colaborador}
                            onClick={() => {
                                const colaboradorSelecionado = colaboradores.find(c => c.colaborador === colaborador);
                                if (colaboradorSelecionado) {
                                    setColaboradorAtivo(colaboradorSelecionado);
                                }
                            }}>
                            {colaborador}
                        </S.Tab>
                    ))}
                </S.Tabs>
                
                <S.SaldoTotal style={{ color: colaboradorAtivo?.saldo_banco_horas >= 0 ? '#4CAF50' : colaboradorAtivo?.saldo_banco_horas < 0 ? '#F44336' : 'black' }}>
                    <div>Total de horas: {colaboradorAtivo?.total_horas_trabalhadas || '00:00'}</div>
                    <div>Horas Extras: {colaboradorAtivo?.total_horas_extras || '00:00'}</div>
                    <div>Atraso: {colaboradorAtivo?.total_atrasos || '00:00'}</div>
                    <div>Banco Horas: {colaboradorAtivo?.saldo_banco_horas || '00:00'}</div>
                </S.SaldoTotal>



                <S.TableContainer>
                    <S.Table>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Dia da Semana</th>
                                {[...Array(colaboradorAtivo?.registros?.reduce((max, reg) => Math.max(max, reg.batidas.length), 0) || 0)].map((_, i) => (
                                    <th key={i}>{i + 1}º Registro</th>
                                ))}
                                <th>Total de Horas</th>
                                <th>Horas Extras</th>
                                <th>Atraso</th>
                                <th>Status</th>
                                <th>Adicionar Registro</th>
                            </tr>
                        </thead>
                        <tbody>
                            {colaboradorAtivo && colaboradorAtivo.registros
                                ?.sort((a, b) => new Date(b.data) - new Date(a.data))
                                .map((registro, index) => (
                                    <tr key={index}>
                                        <td>{format(new Date(registro.data + 'T00:00:00'), "dd-MM-yyyy")}</td>
                                        <td>{getDiaDaSemana(registro.data)}</td>
                                        {registro.batidas.map((batida, idx) => (
                                            <td key={idx}>
                                                {new Date(batida.timestamp).toLocaleTimeString()}
                                                {batida.Registromanual ? (
                                                    <button onClick={() => removerBatida(registro, batida)} className="botao-remover">
                                                        <FaTrash />
                                                    </button>
                                                ) : (
                                                    <button onClick={() => abrirMapa(batida.localizacao)} className="botao-mapa">
                                                        <FaMapMarkerAlt />
                                                    </button>
                                                )}
                                            </td>
                                        ))}
                                        {[...Array(colaboradorAtivo.registros.reduce((max, reg) => Math.max(max, reg.batidas.length), 0) - registro.batidas.length)].map((_, i) => (
                                            <td key={i} className="sem-registro">Sem registro</td>
                                        ))}
                                        <td>{registro.horas_trabalhadas || '00:00'}</td>
                                        <td>{registro.horas_extras || '00:00'}</td>
                                        <td>{registro.atraso || '00:00'}</td>
                                        <td>{registro.batidas.length % 2 === 0 ? <FaCheckCircle color="green" /> : <FaTimesCircle color="red" />}</td>
                                        <td>
                                            <button onClick={() => abrirAjusteManual(registro)} className="botao-adicionar">
                                                <FaPlus />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </S.Table>
                </S.TableContainer>
                <S.SaldoTotal>
                <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                    {/* Componente para gerar o CSV */}
                    <GeraCsvPonto colaboradorAtivo={colaboradorAtivo} />
                    <GeraExcelPonto colaboradorAtivo={colaboradorAtivo} />
                    <GeraPdfPonto colaboradorAtivo={colaboradorAtivo} />

                </div>
                </S.SaldoTotal>

                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={fecharMapa}
                    contentLabel="Local onde o Registro de Ponto foi realizado"
                    style={S.mapModalStyle}
                >
                    {localizacaoAtiva && (
                        <>
                            <h3>Local onde o Registro de Ponto foi realizado</h3>
                            <S.MapContainerWrapper>
                                <MapContainer center={[localizacaoAtiva.latitude, localizacaoAtiva.longitude]} zoom={18} style={{ height: "100%", width: "100%" }}>
                                    <TileLayer attribution='&copy; OpenStreetMap contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                    <Marker position={[localizacaoAtiva.latitude, localizacaoAtiva.longitude]} icon={defaultIcon}>
                                        <Popup>
                                            Localização da batida: {localizacaoAtiva.latitude}, {localizacaoAtiva.longitude}
                                        </Popup>
                                    </Marker>
                                    <ChangeMapView coords={[localizacaoAtiva.latitude, localizacaoAtiva.longitude]} />
                                </MapContainer>
                            </S.MapContainerWrapper>
                            <button onClick={fecharMapa}>Fechar Mapa</button>
                        </>
                    )}
                </Modal>

                <Modal
                    isOpen={modalAjusteIsOpen}
                    onRequestClose={fecharAjusteManual}
                    contentLabel="Ajuste Manual"
                    style={S.modalStyle}
                >
                    {registroSelecionado && (
                        <S.ModalContent>
                            <h3>Ajustar Registro de Ponto - {registroSelecionado.data}</h3>
                            <div className="field">
                                <label>Horário: </label>
                                <input type="time" id="horarioBatida" />
                            </div>
                            <div className="actions">
                                <button onClick={salvarAjuste}>Salvar</button>
                                <button onClick={fecharAjusteManual}>Cancelar</button>
                            </div>
                        </S.ModalContent>
                    )}
                </Modal>
            </S.Content>
            <Footer />
        </S.Container>
    );
}

export default Ponto;
