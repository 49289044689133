import styled from "styled-components";

export const IconContainer = styled.div`
  display: flex;
  justify-content: space-around; // Distribui os ícones uniformemente
  align-items: center; // Centraliza os ícones verticalmente
  flex-wrap: wrap; // Permite que os ícones se ajustem para a próxima linha em telas pequenas
  padding: 20px 0; // Adiciona espaço acima e abaixo dos ícones

  img {
    height: 40px; // Ajuste a altura conforme necessário
    cursor: pointer;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: 768px) {
    justify-content: center; // Centraliza os ícones em telas menores
    gap: 20px; // Adiciona um espaço entre os ícones
  }
  
  @media (max-width: 480px) {
    // Em telas muito pequenas, você pode querer manter os ícones em linha ou empilhá-los
    // dependendo do tamanho deles e do espaço disponível.
    gap: 10px;
  }
`;
