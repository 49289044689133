import React, { useState, useEffect } from 'react';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Nossos Components
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HomeCard from '../../components/HomeCard';
import HomeCabecario from '../../components/HomeCabecario';
import HomeCabecario2 from '../../components/HomeCabecario2';
//icon
import Estabelecimento from '../../assets/Estabelecimento.png';
import Cliente from '../../assets/Cliente.png';
import Colaborador from '../../assets/Colaborador.png';
import Produto from '../../assets/Produto.png';
import Visita from '../../assets/Visitas.png';
import CockPit from '../../assets/CockPit.png';
import secureStorage from '../../utils/SecureStorage';

function Home() {
    const navigate = useNavigate();

    return (
        <S.Container>
            <Header />
            <HomeCabecario title="Cadastros" />
            <S.HomeCardArea>
                {secureStorage.getItem("isVisibleEstabelecimento") && (
                    <button type="button" onClick={() => navigate('/Estabelecimento')}>
                        <HomeCard title={<FormattedMessage id="home.estabelecimentos" defaultMessage="Estabelecimentos" />} icon={Estabelecimento} type="button" />
                    </button>
                )}
                {secureStorage.getItem("isVisibleCliente") && (
                    <button type="button" onClick={() => navigate('/Cliente')}>
                        <HomeCard title={<FormattedMessage id="home.clientes" defaultMessage="Clientes" />} icon={Cliente} type="button" />
                    </button>
                )}
                {secureStorage.getItem("isVisibleColaborador") && (
                    <button type="button" onClick={() => navigate('/colaborador')}>
                        <HomeCard title={<FormattedMessage id="home.colaboradores" defaultMessage="Colaboradores" />} icon={Colaborador} type="button" />
                    </button>
                )}
                {secureStorage.getItem("isVisibleProduto") && (
                    <button type="button" onClick={() => navigate('/produto')}>
                        <HomeCard title={<FormattedMessage id="home.produtos" defaultMessage="Produtos" />} icon={Produto} type="button" />
                    </button>
                )}
            </S.HomeCardArea>
            <HomeCabecario2 title="Acompanhamento" />
            <S.HomeCardArea>
               {secureStorage.getItem("isVisiblePonto") && (
                    <button type="button" onClick={() => navigate('/Ponto')}>
                        <HomeCard title={<FormattedMessage id="home.Registro_Ponto" defaultMessage="Registro Ponto" />} icon={Visita} type="button" />
                    </button>
                )}   
                {secureStorage.getItem("isVisibleVisita") && (
                    <button type="button" onClick={() => navigate('/visita')}>
                        <HomeCard title={<FormattedMessage id="home.agendar_visitas" defaultMessage="Agendar Atendimentos" />} icon={Visita} type="button" />
                    </button>
                )}
                {secureStorage.getItem("isVisibleCockpit") && (
                    <button type="button" onClick={() => navigate('/CockPitGeral')}>
                        <HomeCard title={<FormattedMessage id="home.cockpit" defaultMessage="CockPit" />} icon={CockPit} type="button" />
                    </button>
                )}
                {secureStorage.getItem("isVisibleRelatorio") && (
                    <button type="button" onClick={() => navigate('/Relatorio')}>
                        <HomeCard title={<FormattedMessage id="home.Relatorio" defaultMessage="Relatorios" />} icon={CockPit} type="button" />
                    </button>
                )}
            </S.HomeCardArea>
            <Footer />
        </S.Container>
    );
}

export default Home;
