import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  position: relative;
  margin-bottom: 20px; /* Adiciona espaço inferior */

  @media (max-width: 768px) {
    margin-top: 20px; /* Ajuste a margem superior para telas menores */
    margin-bottom: 30px; /* Ajuste a margem inferior para telas menores */
  }
`;

export const LeftSide = styled.div`
  position: absolute;
  left: 0;
  top: 65%; /* Ajuste o top para afastar mais a linha do centro */
  transform: translateY(-50%);
  width: calc(50% - 10%);
  height: 1px;
  background-color: #cf0505;
`;

export const Center = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RightSide = styled.div`
  position: absolute;
  right: 0;
  top: 65%; /* Ajuste o top para afastar mais a linha do centro */
  transform: translateY(-50%);
  width: calc(50% - 10%);
  height: 1px;
  background-color: #cf0505;
`;

export const Title = styled.span`
  text-align: center;
  padding: 10px 0;
  font-size: 20px;
  color: #cf0505;
  margin-top: 20px; /* Aumenta a margem superior ao título */
`;
