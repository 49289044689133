import React from 'react';
import { PageContainer, HeaderContainer, Content } from './styles'; // Importando os novos estilos
import Header from '../../components/Header_login';

function Info_2() {
  return (
    <PageContainer>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <Content>
        <h1>Info 2</h1>
      </Content>
    </PageContainer>
  );
}

export default Info_2;
