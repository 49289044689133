class ViaCepService {
        static fetchAddress(cep) {
            const apiUrl = process.env.REACT_APP_API_BASE_viacep;
            return fetch(`${apiUrl}${cep}/json`)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error('Falha ao buscar CEP');
                    }
                    return res.json();
                });
        }
}

export default ViaCepService;