import api from '../api';
import secureStorage from '../../utils/SecureStorage';

function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function perfil() {
    try {
        // await delay(10000); // Espera 10 segundos (não 20 como comentado)

        const id_l = secureStorage.getItem("id_l");
        const id_r = secureStorage.getItem("id_r");
        const apiUrl = process.env.REACT_APP_API_BASE_1;

        const response = await api.get(`${apiUrl}${id_l}?id_rede=${id_r}`);
        return response.data;
    } catch (error) {
        console.error("Profile fetch error: ", error);
        throw error; // Opcional, caso você queira propagar o erro para o chamador
    }
}

export default perfil;
