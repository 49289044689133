import api from '../api'; // Ajuste o caminho para sua instância da API

const VisitaServicesBusca = {
  loadregistros: async (idrede, filterActived) => {
    try {
      const apiUrl = process.env.REACT_APP_API_BASE_23;
      const response = await api.get(`${apiUrl}=${filterActived}&id_rede=${idrede}`, {
        headers: {
          id_rede: idrede
        }
      });
      return response.data;
    } catch (error) {
      console.error("Erro ao buscar visitas:", error);
      throw new Error("Erro ao buscar visitas. Veja o console para mais detalhes.");
    }
  }
};

export default VisitaServicesBusca;
