import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px; // Aumentar o espaçamento para separar mais do topo
  position: relative;
`;

export const LeftSide = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: calc(50% - 80px); // Ajuste para alinhar corretamente
  height: 1px;
  background-color: #cf0505;
`;

export const Center = styled.div`
  width: 160px; // Ajuste a largura conforme necessário
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RightSide = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: calc(50% - 80px); // Ajuste para alinhar corretamente
  height: 1px;
  background-color: #cf0505;
`;

export const Title = styled.span`
  text-align: center;
  padding: 10px 0;
  font-size: 20px;
  color: #cf0505;
`;
