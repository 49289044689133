// styles.js
import styled from 'styled-components';
import { TabList, Tab } from 'react-tabs';
import Modal from 'react-modal';

export const Container = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 60px;
  padding-bottom: 60px;  // Aumentando o padding-bottom

  @media (max-width: 768px) {
    padding-top: 100px;
    padding-bottom: 80px;  // Ajuste adicional para telas menores
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 800px;
  background: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #cf0505;
  margin: 0 auto 20px auto;  // Adicionando margem inferior

  @media (max-width: 768px) {
    max-width: 90%;
    padding: 15px;
    margin-bottom: 30px;  // Ajuste adicional para telas menores
  }

  @media (max-width: 480px) {
    max-width: 100%;
    padding: 10px;
    margin-bottom: 40px;  // Ajuste adicional para telas menores
  }
`;

export const Title = styled.h1`
  color: #cf0505;
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
`;

export const InputGroup = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #cf0505;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
`;

export const Button = styled.button`
  width: calc(33.333% - 5px);
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #003366;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px; // Adicionando margem inferior para criar espaço entre o link e os botões

  &:hover {
    background-color: #002244;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledTabList = styled(TabList)`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  border-bottom: 1px solid #ccc;
`;

StyledTabList.tabsRole = 'TabList';

export const StyledTab = styled(Tab)`
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  background: #f0f0f0;
  border: 1px solid #ccc;
  margin-right: -1px;

  &:last-of-type {
    margin-right: 0;
  }

  &.react-tabs__tab--selected {
    color: #cf0505;
    background-color: #f0f0f0;
    border-color: #ccc;
    border-bottom: none;
  }

  @media (max-width: 768px) {
    padding: 6px 12px;
  }

  @media (max-width: 480px) {
    padding: 4px 8px;
  }
`;

StyledTab.tabsRole = 'Tab';

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border-radius: 8px;

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f0f0f0;
    color: #333;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f1f1f1;
  }
`;

export const GtinImageGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  & > div {
    flex: 1;
    margin-right: 10px;
  }

  & > div:last-child {
    margin-right: 0;
  }
`;

export const PriceGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  & > div {
    flex: 1;
    margin-right: 10px;
  }

  & > div:last-child {
    margin-right: 0;
  }
`;

export const DimensionGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  & > div {
    flex: 1;
    margin-right: 10px;
  }

  & > div:last-child {
    margin-right: 0;
  }
`;

export const WeightGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  & > div {
    flex: 1;
    margin-right: 10px;
  }

  & > div:last-child {
    margin-right: 0;
  }
`;

export const Erro = styled.p`
  color: red;
`;

export const StyledModal = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  max-width: 800px;
  width: 90%;
  background: #FFF;
  border-radius: 10px;
  border: none;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    width: 95%;
    padding: 10px;
  }
`;

export const ModalContent = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

export const GtinGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    flex: 1;
    margin-right: 10px;
  }

  & > div:last-child {
    margin-right: 0;
  }
`;

export const LabelGray = styled.label`
  color: gray;
`;

export const Select = styled.select`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

export const ExternalLink = styled.a`
  display: block;
  margin-top: 10px; // Ajuste de margem superior para criar espaço entre o link e os campos acima
  margin-bottom: 20px; // Ajuste de margem inferior para criar espaço entre o link e os botões
  color: #003366;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
