import React from 'react';
import * as S from './styles';

function HomeCabecario({ title }) {
  return (
    <S.Container>
      <S.LeftSide />
      <S.Center>
        <S.Title>{title}</S.Title>
      </S.Center>
      <S.RightSide />
    </S.Container>
  );
}

export default HomeCabecario;
