import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;

export const Content = styled.div`
  flex: 1;
  width: 80%;
  margin: 20px auto;
  padding-top: 20px;
  padding-bottom: 80px;
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 30px;
  z-index: 1;
  position: relative;
  border-bottom: 2px solid #ddd;
`;

export const Tab = styled.button`
  padding: 10px 20px;
  background-color: ${(props) => (props.$isActive ? '#4CAF50' : '#f1f1f1')};
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: ${(props) => (props.$isActive ? '#fff' : '#000')};
  border-radius: 5px 5px 0 0;
  margin-right: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.$isActive ? '#45a049' : '#ddd')};
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  height: auto; 
  max-height: 70vh; // Limita a altura para permitir rolagem quando necessário
  overflow-y: auto; 
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 10px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: center;
  }

  th {
    background-color: #f4f4f4;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .sem-registro {
    color: red;
    font-style: italic;
  }

  .total-horas {
    font-weight: bold;
    text-align: right;
    padding-right: 20px;
  }
`;

export const MapContainerWrapper = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
`;

export const SaldoTotal = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: right;
  padding-right: 10px;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  
  /* Novo estilo para organizar em colunas */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Três colunas de largura igual */
  gap: 10px; /* Espaçamento entre as colunas */
  text-align: left; /* Alinha o texto à esquerda para uma aparência mais limpa */
  color: black; /* Define a cor do texto como preto */
`;



export const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: 'auto',
    zIndex: 1000,
    borderRadius: '15px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    minWidth: '320px',
    maxWidth: '600px',
    maxHeight: '90%',
    overflowY: 'auto'
  },
  overlay: { zIndex: 1000 }
};

export const mapModalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '60%',
    zIndex: 1000,
    borderRadius: '15px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  overlay: { zIndex: 1000 }
};

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .field {
    margin-bottom: 10px;
    label {
      margin-right: 10px;
    }
  }

  .actions {
    margin-top: 20px;
    button {
      margin-right: 10px;
    }
  }
`;
