import api from '../api';

class Del {
    async deleteData(del_colaborador, del_perfil, del_user, id_r) {
        const apiUrl1 = process.env.REACT_APP_API_BASE_9;
        const apiUrl2 = process.env.REACT_APP_API_BASE_10;
        const apiUrl3 = process.env.REACT_APP_API_BASE_11;
        try {
            const response1 = await api.delete(`${apiUrl1}${del_colaborador}?id_rede=${id_r}`);
            if (response1.status !== 200) throw new Error('Erro ao deletar colaborador');

            const response2 = await api.delete(`${apiUrl2}${del_perfil}?id_rede=${id_r}`);
            if (response2.status !== 200) throw new Error('Erro ao deletar perfil');

            const response3 = await api.delete(`${apiUrl3}${del_user}`);
            if (response3.status !== 200) throw new Error('Erro ao deletar usuário');


            alert('Todos os dados foram deletados com sucesso');
        } catch (error) {
            alert(error.message);
        }
    }
}

export default Del;