import { useState } from 'react';
import api from '../api'; // Atualize o caminho para sua instância da API

const BuscaProduto = () => {
  const [searchResultsprodutos, setSearchResultsprodutos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_BASE_19;

  const loadprodutos = async (idrede, searchTerm) => {
    setLoading(true);
    try {
      const response = await api.get(`${apiUrl}${idrede}&nome=${searchTerm}`);
      setSearchResultsprodutos(response.data.cadastros);
      setError(null); // Limpa qualquer erro anterior
    } catch (error) {
      console.error("Erro ao buscar cadastros:", error);
      setError("Erro ao buscar cadastros. Veja o console para mais detalhes.");
      setSearchResultsprodutos([]); // Limpa os resultados anteriores
    } finally {
      setLoading(false);
    }
  };

  return { searchResultsprodutos, loadprodutos, loading, error };
};

export default BuscaProduto;
