import api from '../api';
import secureStorage from '../../utils/SecureStorage';

const PerfilServer = {

  // Função para remover máscaras, se necessário
  removeMask: (value, length) => {
    let newValue = value.replace(/\D/g, '');
    return newValue.slice(-length);
  },

  // Função para validar os campos do perfil
  validateFields: (perfil) => {
    const { nomeUser, id_rede, id_ligacao } = perfil;
    if (!nomeUser) {
      alert("Você precisa informar o Nome de Usuário");
      return false;
    } else if (!id_rede) {
      alert("O ID da rede não pode ser vazio");
      return false;
    } else if (!id_ligacao) {
      alert("Você precisa informar o ID de ligação");
      return false;
    } 
    return true;
  },

  // Função para salvar os dados do perfil
  savePerfil: async (perfil) => {
    if (!PerfilServer.validateFields(perfil)) return;

    const _id = perfil._id.$oid ? perfil._id.$oid : null;
    const apiUrl1 = process.env.REACT_APP_API_BASE_13;
    const apiUrl2 = process.env.REACT_APP_API_BASE_10;
    const { ...perfilData } = perfil;
    perfilData.data_update = new Date();
    perfilData.id_rede = secureStorage.getItem("id_r");

    try {
      let response;
      if (_id) {
        if (!perfil._id.$oid) {
          alert("tivemos um problema ao caregar as informar");
          return false;
        }
        response = await api.put(`${apiUrl2}${_id}?id_rede=${perfilData.id_rede}`, perfilData);
        //alert("Perfil Atualizado Com Sucesso!");
      } else {
        // Criar um novo registro para o perfil
        response = await api.post(apiUrl1, perfilData);
        //alert("Perfil Salvo Com Sucesso!");
      }
      return response.data; // Assumindo que response.data contém o objeto perfil com _id
    } catch (error) {
      console.error("Erro ao salvar o perfil:", error);
      alert("Erro ao salvar o perfil. Por favor, verifique os logs para mais detalhes.");
    }
    return {
      _id: '',
      id_rede: null,
      id_ligacao: '',
      nomeUser: '',
      // Definir os outros campos com seus valores padrão
      // ...
    };
  }
};

export default PerfilServer;