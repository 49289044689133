import React from 'react';
import * as S from './styles';

// Nossos Components
import Header from '../../components/Header';
import Footer from '../../components/Footer';

function Relatorio() {
    return (
        <S.Container>
            <Header />
            <S.Content>
                Página em construção
            </S.Content>
            <Footer />
        </S.Container>
    );
}

export default Relatorio;
