import React, { useState } from 'react';
import * as S from './styles';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import bell from '../../assets/bell.png';
import isConnected from '../../utils/isConnected';
import { FormattedMessage } from 'react-intl';
import secureStorage from '../../utils/SecureStorage';

function Header({ clickNotification }) {
  const [lateCount] = useState(0); // Inicializando com 0

  async function Logout() {
    secureStorage.clear();
    window.location.reload();
  }

  return (
    <S.Container>
      <S.LeftSide>
        <img src={logo} alt="Logo" />
      </S.LeftSide>
      <S.RightSide>
        <Link to="/home">
          <FormattedMessage id="header.home" defaultMessage="INÍCIO" />
        </Link>
        <span className="dividir" />
        <Link to="/visita">
          <FormattedMessage id="header.new_task" defaultMessage="NOVA TAREFA" />
        </Link>
        <span className="dividir" />
        {!isConnected ? (
          <Link to="/">
            <FormattedMessage id="header.logout" defaultMessage="SAIR" />
          </Link>
        ) : (
          <button type="button" onClick={Logout}>
            <FormattedMessage id="header.logout" defaultMessage="SAIR" />
          </button>
        )}
        {lateCount > 0 && (
          <>
            <span className="dividir" />
            <button onClick={clickNotification}>
              <img src={bell} alt="Notificação" />
              <span>{lateCount}</span>
            </button>
          </>
        )}
      </S.RightSide>
    </S.Container>
  );
}

export default Header;
