import api from '../api';
import secureStorage from '../../utils/SecureStorage';

const ClienteService = {
  removeMask: (value, length) => {
    let newValue = value.replace(/\D/g, '');
    return newValue.slice(-length);
  },

  validateFields: (cliente) => {
    const { nome, id_rede, contato, endereco } = cliente;
    if (!nome) {
      alert("Você precisa informar o Nome do Cliente");
      return false;
    } else if (!endereco.rua || !endereco.numero || !endereco.bairro || !endereco.cep || !endereco.cidade || !endereco.estado) {
      alert("Você precisa informar o endereço completo");
      return false;
    }
    return true;
  },

  saveCliente: async (cliente) => {
    if (!ClienteService.validateFields(cliente)) return;

    cliente.cpf_cnpj = ClienteService.removeMask(cliente.cpf_cnpj);
    cliente.endereco.cep = ClienteService.removeMask(cliente.endereco.cep);
    cliente.id_rede = secureStorage.getItem("id_r");
    cliente.data_update = new Date();
    const _id = cliente._id?.$oid; // Usando optional chaining para segurança
    const { ...clienteData } = cliente;
    const apiUr = process.env.REACT_APP_API_BASE_6;
    const apiUrl = process.env.REACT_APP_API_BASE_7;

    try {
      if (_id) {
        clienteData.id = _id;
        await api.put(`${apiUr}${_id}?id_rede=${cliente.id_rede}`, clienteData);
        alert("Cadastro Editado Com Sucesso!");
      } else {
        await api.post(apiUrl, clienteData);
        alert("Cadastro Salvo Com Sucesso!");
      }
    } catch (error) {
      console.error("Erro ao salvar o cadastro:", error);

      // Exibe a mensagem de erro detalhada do servidor, se disponível
      if (error.response && error.response.data && error.response.data.error) {
        alert(`Erro ao salvar o cadastro: ${error.response.data.error}`);
      } else {
        alert("Erro ao salvar o cadastro. ");
      }
    }

    // Retorne um novo objeto cliente vazio para reutilização na UI, se necessário
    return {
      id: '',
      cpf_cnpj: '',
      id_rede: '',
      nome: '',
      sobrenome: '',
      blocked: false,
      inactive: false,
      endereco: {
        rua: '',
        numero: '',
        complemento: '',
        bairro: '',
        cep: '',
        cidade: '',
        estado: ''
      },
      contato: {
        telefone_fixo: '',
        celular: '',
        email: '',
        WhatsApp: '',
        observacoes: ''
      },
    };
  }
};

export default ClienteService;
