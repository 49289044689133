import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Section = styled.div`
  width: 100%;
  padding: 20px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;

export const HomeCardArea = styled.div`
  display: flex;
  justify-content: center; /* Centraliza os cards */
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  button {
    background: none;
    border: none;
    margin: 10px; /* Isso adiciona espaço ao redor de cada botão/card */
  }
`;
