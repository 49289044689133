import React from 'react';
import * as S from './styles';
import { FormattedMessage } from 'react-intl';
import secureStorage from '../../utils/SecureStorage';

function Footer() {
  const name = secureStorage.getItem("nomeUser");

  return (
    <S.Container>
      <S.SpanLeftAlign>
        <FormattedMessage id="footer.name" defaultMessage="Nome:" /> {name}
      </S.SpanLeftAlign>
      <S.SpanCenterAlign>
        <FormattedMessage id="footer.slogan" defaultMessage="MarketDo - Facilitando seu dia!" />
      </S.SpanCenterAlign>
      <S.SpanRightAlign>
        <FormattedMessage id="footer.version" defaultMessage="Versão: beta" />
      </S.SpanRightAlign>
    </S.Container>
  );
}

export default Footer;
