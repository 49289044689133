import React from 'react';
import * as S from './styles';

function HomeCard({ type, icon, title }) {
  return (
    <S.Container>
      <img src={icon} alt={title} />
      <span>{title}</span>
    </S.Container>
  );
}

export default HomeCard;
