// (00) 00000-0000
export const MascaraTelefone = value => {
  return value
  .replace(/\D/g, '') // substitui qualquer caracter que não seja número por nada
  .replace(/(\d{2})(\d)/, '($1) $2') // coloca parênteses em volta dos dois primeiros dígitos
  .replace(/(\d{5})(\d)/, '$1-$2') // coloca um hífen depois do quinto dígito
  .slice(0, 15); // limita o resultado a 15 caracteres
};

export const MascaraTelefoneFixo = value => {
     return value
    .replace(/\D/g, '') // substitui qualquer caracter que não seja número por nada
    .replace(/(\d{2})(\d)/, '($1) $2') // coloca parênteses em volta dos dois primeiros dígitos
    .replace(/(\d{4})(\d)/, '$1-$2') // coloca um hífen depois do quarto dígito
    .slice(0, 14); // limita o resultado a 14 caracteres
}

export const MascaraCep = value => {
  return  value
    .replace(/\D/g, '') // substitui qualquer caracter que não seja número por nada
    .replace(/(\d{5})(\d)/, '$1-$2') // captura 2 grupos de números e insere um '-' entre eles
    .slice(0, 9); // limita o resultado a 9 caracteres
}

// 00/00/0000
export const maskDate = value => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1");
};

// Aceita apenas que letras sejam digitadas
export const maskOnlyLetters = value => {
  return value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, "");
};

// Aceita apenas números
export const maskOnlyNumbers = value => {
  return value.replace(/\D/g, "");
};

export function MascaraRg(v) {
  v = v.replace(/\D/g, "");
  if (v.length === 9) v = v.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4");
  if (v.length === 8) v = v.replace(/(\d{1})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4");
  return v
}

//adiciona mascara de cnpj / cpf
export const MascaraCpf = v => {
  v = v.replace(/\D/g, "")
  v = v.replace(/(\d{3})(\d)/, "$1.$2")
  v = v.replace(/(\d{3})(\d)/, "$1.$2")
  v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  return v
}


export const MascaraCnpj = v => {
  v = v.replace(/\D/g, "")
  v = v.replace(/^(\d{2})(\d)/, "$1.$2")
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
  v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
  v = v.replace(/(\d{4})(\d)/, "$1-$2")
  return v
}

export const MascaraCpfCnpj = v => {
  v = v.replace(/\D/g, "");

  if (v.length <= 11) { // CPF
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else { // CNPJ
    v = v.replace(/^(\d{2})(\d)/, "$1.$2");
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
    v = v.replace(/(\d{4})(\d)/, "$1-$2");
  }

  return v;
}

export function validaRg(rg) {
  
  const rgFormato = /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}-[0-9]{1}$/;
  return rgFormato.test(rg);
}

export const  ValidaEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const ValidaCpf = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g,'');
  if(cpf === '') return false;
  if (cpf.length !== 11 || 
    cpf === "00000000000" || 
    cpf === "11111111111" || 
    cpf === "22222222222" || 
    cpf === "33333333333" || 
    cpf === "44444444444" || 
    cpf === "55555555555" || 
    cpf === "66666666666" || 
    cpf === "77777777777" || 
    cpf === "88888888888" || 
    cpf === "99999999999")
      return false;
  let add = 0;
  for (let i=0; i < 9; i ++)
    add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11)
    rev = 0;
  if (rev !== parseInt(cpf.charAt(9)))
    return false;
  add = 0;
  for (let i = 0; i < 10; i ++)
    add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11)
    rev = 0;
  if (rev !== parseInt(cpf.charAt(10)))
    return false;
  return true;
}

export const ValidaCnpj = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g,'');
  if(cnpj === '') return false;
  if (cnpj.length !== 14)
    return false;
  if (cnpj === "00000000000000" || 
    cnpj === "11111111111111" || 
    cnpj === "22222222222222" || 
    cnpj === "33333333333333" || 
    cnpj === "44444444444444" || 
    cnpj === "55555555555555" || 
    cnpj === "66666666666666" || 
    cnpj === "77777777777777" || 
    cnpj === "88888888888888" || 
    cnpj === "99999999999999")
      return false;
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0,tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
  soma += numeros.charAt(tamanho - i) * pos--;
  if (pos < 2)
      pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado !== parseInt(digitos.charAt(0)))
    return false;
  tamanho = tamanho + 1;
  numeros = cnpj.substring(0,tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
  soma += numeros.charAt(tamanho - i) * pos--;
  if (pos < 2)
      pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado !== parseInt(digitos.charAt(1)))
    return false;
  return true;
}