import CryptoJS from 'crypto-js';

// Obtendo a chave secreta das variáveis de ambiente
const secretKey = process.env.REACT_APP_SECRET_KEY;



class SecureStorage {
    constructor() {
        this.secretKey = secretKey;
    }

    // Função para criptografar dados
    encryptData(data) {
        try {
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), this.secretKey).toString();
            return encryptedData;
        } catch (error) {
            console.error("Erro ao criptografar dados:", error);
        }
    }

    // Função para descriptografar dados
    decryptData(cipherText) {
        try {
            const bytes = CryptoJS.AES.decrypt(cipherText, this.secretKey);
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return decryptedData;
        } catch (error) {
            console.error("Erro ao descriptografar dados:", error);
        }
    }

    // Função para armazenar dados no sessionStorage
    setItem(key, data) {
        const encryptedData = this.encryptData(data);
        sessionStorage.setItem(key, encryptedData);
    }

    // Função para recuperar dados do sessionStorage
    getItem(key) {
        const encryptedData = sessionStorage.getItem(key);
        if (!encryptedData) {
            return null;
        }
        return this.decryptData(encryptedData);
    }

    // Função para remover dados do sessionStorage
    removeItem(key) {
        sessionStorage.removeItem(key);
    }

    // Função para limpar todos os dados do sessionStorage
    clear() {
        sessionStorage.clear();
    }
}

// Exportar a classe para uso em outros arquivos
const secureStorage = new SecureStorage();
export default secureStorage;
