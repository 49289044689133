import { useState } from 'react';
import api from '../api'; // Atualize o caminho para sua instância da API

const BuscaProdutoCliente = () => {
  const [searchResultsprodutos, setSearchResultsprodutos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_BASE_19;

  const loadprodutos = async (idrede, idCliente) => {
    setLoading(true);
    try {
      const response = await api.get(`${apiUrl}${idrede}&id_Cliente=${idCliente}`);
      if (response.data && response.data.length > 0) {
        setSearchResultsprodutos(response.data);
      } else {
        setSearchResultsprodutos([]);
      }
      setError(null);
    } catch (error) {
      console.error("Erro ao buscar produtos:", error);
      setError("Erro ao buscar produtos. Veja o console para mais detalhes.");
      setSearchResultsprodutos([]);
    } finally {
      setLoading(false);
    }
  };

  return { searchResultsprodutos, loadprodutos, loading, error };
};

export default BuscaProdutoCliente;
