import styled from 'styled-components';
import { TabList, Tab } from 'react-tabs';
import Modal from 'react-modal';

export const Container = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 60px;
  padding-bottom: 30px;

  @media (max-width: 1024px) {
    min-height: auto;
    overflow-y: auto;
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 900px;
  background: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #cf0505;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 90%;
    padding: 15px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    padding: 10px;
  }
`;

export const Title = styled.h1`
  color: #cf0505;
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

export const InputGroup = styled.div`
  margin-bottom: 15px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #cf0505;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
`;

export const Button = styled.button`
  width: calc(50% - 5px);
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #003366;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    background-color: #002244;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const SmallButton = styled(Button)`
  width: auto;
  padding: 5px 10px;
  margin-left: 10px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Fieldset = styled.fieldset`
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 20px;
`;

export const Legend = styled.legend`
  padding: 0 5px;
  color: #333;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const Checkbox = styled.input`
  margin-left: 10px;
`;

export const StyledTabList = styled(TabList)`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
  border-bottom: 1px solid #ccc;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

StyledTabList.tabsRole = 'TabList';

export const StyledTab = styled(Tab)`
  flex-shrink: 0;
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  background: #f0f0f0;
  border: 1px solid #ccc;
  margin-right: -1px;
  &:last-of-type {
    margin-right: 0;
  }
  &.react-tabs__tab--selected {
    color: #cf0505;
    background-color: #f0f0f0;
    border-color: #ccc;
    border-bottom: none;
  }

  @media (max-width: 768px) {
    padding: 4px 8px;
    font-size: 14px;
  }
`;

StyledTab.tabsRole = 'Tab';

export const TextArea = styled.textarea`
  width: 100%;
  height: 120px;
`;

export const Erro = styled.p`
  color: red;
`;

export const StyledModal = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  max-width: 800px;
  width: 90%;
  background: #FFF;
  border-radius: 10px;
  border: none;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    width: 95%;
    padding: 10px;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border-radius: 8px;

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f0f0f0;
    color: #333;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f1f1f1;
  }
`;

export const ModalContent = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

export const Select = styled.select`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

export const ModuleSeparator = styled.div`
  width: 100%;
  text-align: center;
  border-top: 1px solid #ccc;
  line-height: 0.1em;
  margin: 20px 0 20px;
`;

export const ModuleTitle = styled.span`
  background: #f0f0f0;
  padding: 0 10px;
  color: #cf0505;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FlexItem = styled.div`
  flex: 1;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px; // Adicione esta linha para criar espaço acima do botão
  gap: 10px; // Ajusta o espaçamento entre os botões
`;
